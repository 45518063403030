import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const init = () => {

    const historyElements = gsap.utils.toArray(".section-history__item");


    historyElements.forEach(function(elem, index){

        ScrollTrigger.matchMedia({

            // large
            "(min-width: 680px)": function() {

                const direction = index % 2 == 0 ? -1 : 1;

                gsap.fromTo(elem,
                    {
                        xPercent: 25 * direction,
                        autoAlpha: 0
                    },
                    {
                        scrollTrigger: {
                            trigger: elem.querySelector('.section-history__item-headline'),
                            start: 'top bottom',
                            end: 'bottom top',
                            invalidateOnRefresh: true,
                            // markers: true,
                            toggleActions: 'play reverse play reverse',
                        },
                        xPercent: 0,
                        autoAlpha: 1
                    }
                );
            },


            // small
            "(max-width: 679px)": function() {
                const direction = 1;

                gsap.fromTo(elem,
                    {
                        xPercent: 25 * direction,
                        autoAlpha: 0
                    },
                    {
                        scrollTrigger: {
                            trigger: elem.querySelector('.section-history__item-headline'),
                            start: 'top bottom',
                            end: 'bottom top',
                            invalidateOnRefresh: true,
                            // markers: true,
                            toggleActions: 'play reverse play reverse',
                        },
                        xPercent: 0,
                        autoAlpha: 1
                    }
                );
            },


          });


    })


}

export default init;




