<template>

    <transition name="open-overlay" appear @before-enter="beforeOpen" @after-leave="afterClose" @key="name">

        <div v-if="isOpen" class="overlay">

            <div class="overlay__wrapper">

                <div v-if="$slots['icon']" class="overlay__icon">
                    <slot name="icon" />
                </div>

                <div class="overlay__content">

                    <span class="overlay__close-button" @click="closeOverlay">
                        <slot name="close-icon">x</slot>
                    </span>

                    <div class="overlay__scroll-container">
                        <slot />
                    </div>
                </div>
            </div>

        </div>

    </transition>

</template>

<script>
import { disableScrolling, enableScrolling } from '@/utils/ScrollBlocker';

export default {
    name: 'Overlay',

    inject: [ 'registerOverlay', 'deRegisterOverlay' ],

    props: {
        name: {
            type: String,
            required: true
        },
        open: false
    },

    data() {
        return {
            isOpen: false
        }
    },

    created() {
        this.registerOverlay(this.name, this);
        this.isOpen = this.open;
    },

    beforeUnmount() {
        this.deRegisterOverlay(this.name, this);
        enableScrolling();
        this.removeKeyListener();
    },

    methods: {

        beforeOpen() {
            disableScrolling();
            this.addKeyListener();
        },

        afterClose() {
            enableScrolling();
            this.removeKeyListener();
        },

        openOverlay() {
            this.isOpen = true;
        },

        closeOverlay() {
            this.isOpen = false;
        },

        toggle() {
            this.isOpen ? this.closeOverlay() : this.openOverlay();
        },

        addKeyListener() {
            document.addEventListener('keydown', this.keyListener);
        },

        removeKeyListener() {
            document.removeEventListener('keydown', this.keyListener);
        },

        keyListener(event) {

            if (this.isOpen && event.keyCode == 27) {
                this.closeOverlay();
            }
        },

    }
}
</script>
