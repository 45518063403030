<template>

    <div class="section-app-slider__navigation-elements" ref="navigationElements">
        <swiper
            :modules="swiperModulesFirstSwiper"
            :style="componentStyle"
            :slides-per-view="1.5"
            :center-insufficient-slides="true"
            :centered-slides="true"
            :space-between="20"
            :controller="{ control: secondSwiper }"
            :slide-to-clicked-slide="true"
            :breakpoints="{
                enabled: true,
                slidesPerView: 1.5,
                680: {
                    slidesPerView: 2.5
                },
                786: {
                    slidesPerView: 3.5
                },
                1024: {
                    enabled: false,
                    autoHeight: false
                }
            }"
            @swiper="setFirstSwiper"
        >
            <swiper-slide v-for="comp, index in navigationElements" :key="index">

                 <component  :is="comp" @click="slideTo(index)" :class="navElementClass(index)" />

            </swiper-slide>

        </swiper>
    </div>

    <swiper
        class="app-slider section-app-slider__slider"

        :modules="swiperModulesSecondSwiper"
        :autoplay="autoplayConfig"
        @swiper="setSecondSwiper"
        @slide-change="setActiveNavElement"
        @resize="handleResize"
        :controller="{ control: firstSwiper }"
        :navigation="false"
    >
        <slot name="navigation-buttons" />
        <slot />

    </swiper>

</template>

<script>
import { ref } from 'vue';
import { Navigation, Controller, Autoplay } from 'swiper';


export default {

    name: 'AppSlider',

    props: {

    },

    data: () => {
        return {
            swiperModulesFirstSwiper: [
                Controller,
            ],
            swiperModulesSecondSwiper: [
                Controller,
                // Navigation,
                Autoplay
            ],
            activeIndex: 0,
            autoplayDuration: 10000,
        };
    },

    setup() {
      const firstSwiper = ref(null);
      const secondSwiper = ref(null);
      const setFirstSwiper = (swiper) => {
        firstSwiper.value = swiper;
      };
      const setSecondSwiper = (swiper) => {
        secondSwiper.value = swiper;
      };

      return {
        Controller,
        firstSwiper,
        secondSwiper,
        setFirstSwiper,
        setSecondSwiper,
      };
    },


    computed: {
        navigationElements() {
            return this.$slots['navigation-elements'] && this.$slots['navigation-elements']();
        },

        componentStyle() {

            return {
                '--autoplay-duration': this.autoplayDuration + 'ms'
            };

        },

        autoplayConfig() {
            return {
                delay: this.autoplayDuration,
                disableOnInteraction: false
            }
        }
    },

    methods: {

        navElementClass(index) {

            return this.secondSwiper && [
                'app-slider__navigation-element',
                {
                    'app-slider__navigation-element--is-active': this.activeIndex == index
                }
            ];
        },


        slideTo(index) {

            if (!this.secondSwiper) {
                return;
            }

            this.secondSwiper.slideTo(index);
        },

        handleResize(swiper) {

            if (!swiper) {
                return;
            }

            const bounding = this.$refs['navigationElements'].querySelector('.app-slider__navigation-element');
            const sliderSection = this.$refs['navigationElements'].closest('section');

            sliderSection.style.setProperty('--decoration-position', `${bounding.offsetTop}px`);
        },

        disableNavigationSlider() {
            swiper.disable()
        },

        setActiveNavElement(swiper) {
            this.activeIndex = swiper.realIndex;
        },

    },
}
</script>
