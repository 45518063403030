import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


export const init = () => {
    const iconSections = gsap.utils.toArray(".icons");

    iconSections.forEach(function(iconSection){

        ScrollTrigger.batch(iconSection.querySelectorAll(".icons__icon"), {
            interval: 0.1,
            batchMax: 3,
            start: 'top 80%',
            invalidateOnRefresh: true,
            // markers: true,
            onEnter: batch => gsap.to(batch, {autoAlpha: 1, x: 0, stagger: 0.15, overwrite: true}),
            onLeaveBack: batch => gsap.to(batch, {autoAlpha: 0, x: 0, stagger: 0.15, overwrite: true})
        });

    });
}

export default init;
